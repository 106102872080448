import React from 'react';
import { Menu } from 'antd';
import { useSelector } from 'react-redux';
import { NavLink, useRouteMatch } from 'react-router-dom';
import FeatherIcon from 'feather-icons-react';
import propTypes from 'prop-types';
import { PERMISSIONS } from '../config/permissions';

const MenuItems = ({ darkMode, toggleCollapsed, topMenu }) => {
  const permissions = useSelector((state) => state.auth.user.permissions);
  const { path } = useRouteMatch();
  const pathName = window.location.pathname;
  const mainPathSplit = pathName.split('/');
  const [openKeys, setOpenKeys] = React.useState(!topMenu ? [`${mainPathSplit[1] !== '' ? mainPathSplit[1] : 'dashboard'}`] : []);

  const onOpenChange = (keys) => {
    setOpenKeys(keys[keys.length - 1] !== 'recharts' ? [keys.length && keys[keys.length - 1]] : keys);
  };

  const onClick = (item) => {
    if (item.keyPath.length === 0) setOpenKeys([]);
  };

  const selectedKeys = () => {
    const keys = [];
    switch (mainPathSplit.length) {
      case 1:
        if (mainPathSplit[0] === '') {
          keys[0] = 'dashboard-general';
        } else {
          [keys[0]] = mainPathSplit;
        }
        break;

      case 2:
        if (mainPathSplit[1] === 'dashboard') {
          keys[0] = 'dashboard-general';
        } else {
          [, keys[0]] = mainPathSplit;
        }
        break;

      case 3:
        if (['user'].includes(mainPathSplit[1])) {
          [, keys[0]] = mainPathSplit;
        } else {
          keys[0] = `${mainPathSplit[1]}-${mainPathSplit[2]}`;
        }
        break;

      case 4:
        if (['user'].includes(mainPathSplit[1])) {
          [, keys[0]] = mainPathSplit;
        } else {
          keys[0] = `${mainPathSplit[1]}-${mainPathSplit[2]}`;
        }
        break;

      case 5:
        keys[0] = `${mainPathSplit[1]}-${mainPathSplit[2]}`;
        break;

      default:
        [, keys[0]] = mainPathSplit;
        break;
    }
    return keys;
  };

  const items = [
    {
      label: 'Dashboard',
      key: 'dashboard',
      children: [
        {
          label: (
            <NavLink onClick={toggleCollapsed} to={`${path}dashboard`}>
              General
            </NavLink>
          ),
          key: 'dashboard-general',
        },
        permissions.includes(PERMISSIONS.dashboard.maintenance.value) && {
          label: (
            <NavLink onClick={toggleCollapsed} to={`${path}dashboard/workshop`}>
              Workshop
            </NavLink>
          ),
          key: 'dashboard-workshop',
        },
        permissions.includes(PERMISSIONS.dashboard.warehouse.value) && {
          label: (
            <NavLink onClick={toggleCollapsed} to={`${path}dashboard/warehouse`}>
              Warehouse
            </NavLink>
          ),
          key: 'dashboard-warehouse',
        },
      ],
      icon: !topMenu && (
        <NavLink className="menuItem-iocn" to={`${path}dashboard`}>
          <FeatherIcon icon="home" color="#FF0000" />
        </NavLink>
      ),
    },
    permissions.some((permission) => PERMISSIONS.master.views.includes(permission)) && {
      label: 'Master',
      key: 'master',
      children: [
        permissions.includes(PERMISSIONS.master.sub.customer.view.value) && {
          label: (
            <NavLink onClick={toggleCollapsed} to={`${path}master/customer`}>
              Customer
            </NavLink>
          ),
          key: 'master-customer',
        },
        permissions.includes(PERMISSIONS.master.sub.customerGroup.view.value) && {
          label: (
            <NavLink onClick={toggleCollapsed} to={`${path}master/customer-group`}>
              Customer Group
            </NavLink>
          ),
          key: 'master-customer-group',
        },
        permissions.includes(PERMISSIONS.master.sub.employee.view.value) && {
          label: (
            <NavLink onClick={toggleCollapsed} to={`${path}master/employee`}>
              Employee
            </NavLink>
          ),
          key: 'master-employee',
        },
        permissions.includes(PERMISSIONS.master.sub.jobPackage.view.value) && {
          label: (
            <NavLink onClick={toggleCollapsed} to={`${path}master/job-package`}>
              Job Package
            </NavLink>
          ),
          key: 'master-job-package',
        },
        permissions.includes(PERMISSIONS.master.sub.manufacturer.view.value) && {
          label: (
            <NavLink onClick={toggleCollapsed} to={`${path}master/manufacturer`}>
              Manufacturer
            </NavLink>
          ),
          key: 'master-manufacturer',
        },
        process.env.NODE_ENV === 'development' &&
          permissions.includes(PERMISSIONS.master.sub.route.view.value) && {
            label: (
              <NavLink onClick={toggleCollapsed} to={`${path}master/route`}>
                Route
              </NavLink>
            ),
            key: 'master-route',
          },
        permissions.includes(PERMISSIONS.master.sub.package.view.value) && {
          label: (
            <NavLink onClick={toggleCollapsed} to={`${path}master/package`}>
              Service
            </NavLink>
          ),
          key: 'master-package',
        },
        permissions.includes(PERMISSIONS.master.sub.sparepart.view.value) && {
          label: (
            <NavLink onClick={toggleCollapsed} to={`${path}master/sparepart`}>
              Sparepart
            </NavLink>
          ),
          key: 'master-sparepart',
        },
        permissions.includes(PERMISSIONS.master.sub.supplier.view.value) && {
          label: (
            <NavLink onClick={toggleCollapsed} to={`${path}master/supplier`}>
              Supplier
            </NavLink>
          ),
          key: 'master-supplier',
        },
        permissions.includes(PERMISSIONS.master.sub.uom.view.value) && {
          label: (
            <NavLink onClick={toggleCollapsed} to={`${path}master/uom`}>
              UoM
            </NavLink>
          ),
          key: 'master-uom',
        },
        permissions.includes(PERMISSIONS.master.sub.vehicle.view.value) && {
          label: (
            <NavLink onClick={toggleCollapsed} to={`${path}master/vehicle`}>
              Vehicle
            </NavLink>
          ),
          key: 'master-vehicle',
        },
        permissions.includes(PERMISSIONS.master.sub.vehicleModel.view.value) && {
          label: (
            <NavLink onClick={toggleCollapsed} to={`${path}master/vehicle-model`}>
              Vehicle Model
            </NavLink>
          ),
          key: 'master-vehicle-model',
        },
      ],
      icon: !topMenu && (
        <NavLink className="menuItem-iocn btnMenu-icon" to="#">
          <FeatherIcon icon="grid" />
        </NavLink>
      ),
    },
    permissions.includes(PERMISSIONS.vehicleLicense.view) && {
      label: (
        <NavLink onClick={toggleCollapsed} to={`${path}vehicle-license`}>
          Vehicle License
        </NavLink>
      ),
      key: 'vehicle-license',
      icon: !topMenu && (
        <NavLink className="menuItem-iocn" to={`${path}vehicle-license`}>
          <FeatherIcon icon="file" />
        </NavLink>
      ),
    },
    permissions.includes(PERMISSIONS.kimper.view) && {
      label: (
        <NavLink onClick={toggleCollapsed} to={`${path}kimper`}>
          Kimper
        </NavLink>
      ),
      key: 'kimper',
      icon: !topMenu && (
        <NavLink className="menuItem-iocn" to={`${path}kimper`}>
          <FeatherIcon icon="user-check" />
        </NavLink>
      ),
    },
    permissions.some((permission) => PERMISSIONS.maintenance.views.includes(permission)) && {
      label: 'Maintenance',
      key: 'maintenance',
      children: [
        permissions.includes(PERMISSIONS.maintenance.sub.vehicleMaintenance.view.value) && {
          label: (
            <NavLink onClick={toggleCollapsed} to={`${path}maintenance/vehicle`}>
              Vehicle Maintenance
            </NavLink>
          ),
          key: 'maintenance-vehicle',
        },
        permissions.includes(PERMISSIONS.maintenance.sub.listMaintenance.view.value) && {
          label: (
            <NavLink onClick={toggleCollapsed} to={`${path}maintenance/list`}>
              Maintenance List
            </NavLink>
          ),
          key: 'maintenance-list',
        },
        permissions.includes(PERMISSIONS.maintenance.sub.workOrder.view.value) && {
          label: (
            <NavLink onClick={toggleCollapsed} to={`${path}maintenance/work-order`}>
              Work Order
            </NavLink>
          ),
          key: 'maintenance-work-order',
        },
      ],
      icon: !topMenu && (
        <NavLink className="menuItem-iocn btnMenu-icon" to="#">
          <FeatherIcon icon="alert-circle" />
        </NavLink>
      ),
    },
    process.env.NODE_ENV === 'development' &&
      permissions.some((permission) => PERMISSIONS.operation.views.includes(permission)) && {
        label: 'Operation',
        key: 'operation',
        children: [
          permissions.includes(PERMISSIONS.operation.sub.daily.view.value) && {
            label: (
              <NavLink onClick={toggleCollapsed} to={`${path}operation/daily-log`}>
                Daily Log
              </NavLink>
            ),
            key: 'operation-daily-log',
          },
          permissions.includes(PERMISSIONS.operation.sub.trip.view.value) && {
            label: (
              <NavLink onClick={toggleCollapsed} to={`${path}operation/trip`}>
                Trip
              </NavLink>
            ),
            key: 'operation-trip',
          },
        ],
        icon: !topMenu && (
          <NavLink className="menuItem-iocn btnMenu-icon" to="#">
            <FeatherIcon icon="flag" />
          </NavLink>
        ),
      },
    permissions.some((permission) => PERMISSIONS.partRequisition.views.includes(permission)) && {
      label: 'Part Requisition',
      key: 'part-requisition',
      children: [
        permissions.includes(PERMISSIONS.partRequisition.sub.partRequisition.view.value) && {
          label: (
            <NavLink onClick={toggleCollapsed} to={`${path}part-requisition/part-requisition`}>
              Part Requisition
            </NavLink>
          ),
          key: 'part-requisition-part-requisition',
        },
        permissions.includes(PERMISSIONS.partRequisition.sub.purchaseOrder.view.value) && {
          label: (
            <NavLink onClick={toggleCollapsed} to={`${path}part-requisition/purchase-order`}>
              Purchase Order
            </NavLink>
          ),
          key: 'part-requisition-purchase-order',
        },
        permissions.includes(PERMISSIONS.partRequisition.sub.sparepartRequirement.view.value) && {
          label: (
            <NavLink onClick={toggleCollapsed} to={`${path}part-requisition/sparepart-requirement`}>
              Sparepart Requirement
            </NavLink>
          ),
          key: 'part-requisition-sparepart-requirement',
        },
      ],
      icon: !topMenu && (
        <NavLink className="menuItem-iocn btnMenu-icon" to="#">
          <FeatherIcon icon="box" />
        </NavLink>
      ),
    },
    permissions.some((permission) => PERMISSIONS.warehouseInventory.views.includes(permission)) && {
      label: 'Warehouse Inventory',
      key: 'warehouse-inventory',
      children: [
        permissions.includes(PERMISSIONS.warehouseInventory.sub.inventory.view.value) && {
          label: (
            <NavLink onClick={toggleCollapsed} to={`${path}warehouse-inventory/good-receipt-note`}>
              Good Receipt
            </NavLink>
          ),
          key: 'warehouse-inventory-good-receipt-note',
        },
        permissions.includes(PERMISSIONS.warehouseInventory.sub.goodIssue.view.value) && {
          label: (
            <NavLink onClick={toggleCollapsed} to={`${path}warehouse-inventory/good-issue-note`}>
              Good Issue
            </NavLink>
          ),
          key: 'warehouse-inventory-good-issue-note',
        },
        permissions.includes(PERMISSIONS.warehouseInventory.sub.goodsRequest.view.value) && {
          label: (
            <NavLink onClick={toggleCollapsed} to={`${path}warehouse-inventory/goods-request`}>
              Goods Request
            </NavLink>
          ),
          key: 'warehouse-inventory-goods-request',
        },
        permissions.includes(PERMISSIONS.warehouseInventory.sub.stockOpname.view.value) && {
          label: (
            <NavLink onClick={toggleCollapsed} to={`${path}warehouse-inventory/stock-opname`}>
              Stock Opname
            </NavLink>
          ),
          key: 'warehouse-inventory-stock-opname',
        },
        permissions.includes(PERMISSIONS.warehouseInventory.sub.inventory.view.value) && {
          label: (
            <NavLink onClick={toggleCollapsed} to={`${path}warehouse-inventory/inventory`}>
              Inventory
            </NavLink>
          ),
          key: 'warehouse-inventory-inventory',
        },
      ],
      icon: !topMenu && (
        <NavLink className="menuItem-iocn btnMenu-icon" to="#">
          <FeatherIcon icon="grid" />
        </NavLink>
      ),
    },

    permissions.some((permission) => PERMISSIONS.users.views.includes(permission)) && {
      label: 'Users',
      key: 'users',
      children: [
        permissions.includes(PERMISSIONS.users.sub.user.view.value) && {
          label: (
            <NavLink onClick={toggleCollapsed} to={`${path}users/user`}>
              User
            </NavLink>
          ),
          key: 'users-user',
        },
        permissions.includes(PERMISSIONS.users.sub.role.view.value) && {
          label: (
            <NavLink onClick={toggleCollapsed} to={`${path}users/role`}>
              Role
            </NavLink>
          ),
          key: 'users-role',
        },
      ],
      icon: !topMenu && (
        <NavLink className="menuItem-iocn" to={`${path}user`}>
          <FeatherIcon icon="users" />
        </NavLink>
      ),
    },
  ];

  return (
    <Menu
      onOpenChange={onOpenChange}
      onClick={onClick}
      mode={!topMenu || window.innerWidth <= 991 ? 'inline' : 'horizontal'}
      theme={darkMode && 'dark'}
      // // eslint-disable-next-line no-nested-ternary
      defaultSelectedKeys={
        !topMenu
          ? [`${mainPathSplit.length === 1 ? (mainPathSplit[0] === '' ? 'dashboard' : mainPathSplit[0]) : mainPathSplit.length === 2 ? mainPathSplit[1] : mainPathSplit[2]}`]
          : []
      }
      defaultOpenKeys={!topMenu ? [`${mainPathSplit.length > 1 ? mainPathSplit[0] : 'dashboard'}`] : []}
      overflowedIndicator={<FeatherIcon icon="more-vertical" />}
      openKeys={openKeys}
      selectedKeys={selectedKeys()}
      items={items}
    />
  );
};

MenuItems.propTypes = {
  darkMode: propTypes.bool,
  topMenu: propTypes.bool,
  toggleCollapsed: propTypes.func,
};

export default MenuItems;
